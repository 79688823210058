<template>
<div class="examin" v-loading="pageLoading">
    
    <img :src="img"/>
    <h3>{{info && info.name}}</h3>
    <span>满分{{info && info.score}}分，{{info && info.pass}}分合格</span>
    <p @click="jumpDetail">开始答题</p>
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      pageLoading:true,
      id:'',
      info:null,
      img:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BB%84%2048.png'
    };
  },
  async mounted() {
    this.id = this.$route.query.id
    this.getData();
    
  },
  
  methods: {
    
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/flat/test/index",
        data: {
          test_id: this.id,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.info = res.data.test_detail;
      } else {
        this.$toast(res.msg);
      }
    },
    
    jumpDetail() {
      this.$router.push(`./examinList?id=${this.id}&isExamin=true`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  width:1200px;
  background: #fff;
  margin: 20px auto;
  h3{
    font-size: 28px;
    padding-top:45px;
    padding-bottom: 20px;
    margin: 0;
  }
  span{
    font-size: 20px;
  }
  p{
    cursor: pointer;
    margin-top:76px;
    width: 164px;
    height: 36px;
    background: #A40001;
    border-radius: 6px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 16px;
  }
}
</style>

